.link {
  display: inline-block;
  overflow: hidden;
  position: relative;
  vertical-align: bottom;

  &::after {
    content: "";
    background-color: #4e4e53;
    bottom: 3px;
    display: block;
    height: 0.1rem;
    left: 0;
    position: absolute;
    transition: 600ms ease;
    width: 100%;
  }

  &:hover {
    &::after {
      animation: link-hover 600ms ease 0s 1 forwards;
    }
  }
}

.link--dark {
  color: $colour--grit-100;

  &::after {
    background-color: $colour--grit-100;
  }

  &:visited {
    color: $colour--grit-100;
  }
}