.no-margin {
  margin: 0;
}

.no-margin--bottom {
  margin-bottom: 0;
}

.no-margin--top {
  margin-top: 0;
}