.aside {
  grid-column: 3 / 9;

  @include media-query($breakpoint--medium - 1, max-width) {  
    grid-template-columns: repeat(4, 1fr);
    display: grid;
    grid-column-gap: spacing(2);
  }

  @media screen and (min-width: $breakpoint--small) and (max-width: $breakpoint--medium - 1) {
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: spacing(3);
  }

  @include media-query($breakpoint--medium) {
    grid-column: 1 / 3;
  }
}

.aside__content {
  & + & {
    @include media-query($breakpoint--medium) {
      margin-top: spacing(9);
    }
  }

  &:first-child {
    @include media-query($breakpoint--medium - 1, max-width) {
      grid-column: 1 / 3;
    }
  }

  &:nth-child(2) {
    @include media-query($breakpoint--small - 1, max-width) {
      grid-column: 3 / 5;
    }

    @media screen and (min-width: $breakpoint--small) and (max-width: $breakpoint--medium - 1) {
      grid-column: 4 / 7;
    }
  }
}