.block {
  display: block;
}

.block--full-width {
  @extend .block;
  width: 100%;
}

.flex {
  display: flex;
}

.flex--column {
  flex-direction: column;
}

.flex--end {
  align-items: flex-end;
}

.inline-block {
  display: inline-block;
}