.hamburger {
  display: inline-block;
  cursor: pointer;
  width: 2.4rem;
}

.hamburger__line {
  display: block;
  height: 3px;
  background-color: $colour--grit-500;
  transition: 0.4ms ease;

  & + & {
    margin-top: $spacing-rule-half;

    @include media-query($breakpoint--large) {
      margin-top: $spacing-rule;
    }
  }
}

.inverted {
  .hamburger__line {
    &:first-child {
      transform: translateY(0.35rem) rotate(45deg);

      @include media-query($breakpoint--large) {
        transform: translateY(0.55rem) rotate(45deg);
      }
    }
    
    &:last-child {
      transform: translateY(-0.35rem) rotate(-45deg);

      @include media-query($breakpoint--large) {
        transform: translateY(-0.55rem) rotate(-45deg);
      }
    }
  }
}