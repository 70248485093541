.section--l {
  @include section--l;
}

.section--l-top {
  @include section--l(top, padding);
}

.section--l-bottom {
  @include section--l(bottom, padding);
}

.section--s {
  @include section--s;
}

.section--s-top {
  @include section--s(top, padding);
}

.section-xs {
  @include section--xs;
}

.section--container {
  padding-top: spacing(6) + 5.6rem;

  @include media-query($breakpoint--small) {
    padding-top: spacing(8)+ 5.6rem;
  }
  
  @include media-query($breakpoint--medium) {
    padding-top: spacing(10) + 5.6rem;
  }

  @include media-query($breakpoint--x-large) {
    padding-top: spacing(13) + 5.6rem;
  }

  @include media-query($breakpoint--xx-large) {
    padding-top: spacing(13) + 6rem;
  }
}