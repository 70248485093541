@keyframes link-hover {
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(100%);
  }

  50.01% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes link-hover--inverted {
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(-100%);
  }

  50.01% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0);
  }
}