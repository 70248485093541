.home {
  @extend .bg--grit-50;
}

.home-hero__title {
  position: relative;
  z-index: 2;

  @include media-query($breakpoint--small) {
    grid-column: 1 / 9;
  }

  @include media-query($breakpoint--x-large) {
    grid-column: 1 / 11;
  }

  h1 {
    max-width: 40rem;
    margin-left: auto;
    margin-right: auto;

    @include media-query($breakpoint--small) {
      max-width: 55rem;
    }

    @include media-query($breakpoint--medium) {
      max-width: 70rem;
    }
    
    @include media-query($breakpoint--x-large) {
      max-width: 100rem;
    }

    @include media-query($breakpoint--tweakpoint) {
      max-width: 120rem;
    }

    @include media-query($breakpoint--xxx-large) {
      max-width: 140rem;
    }
  }
}

.home-hero__title-last {
  padding-left: 3%;
  padding-right: 3%;
  margin-left: -3%;
}

.home-hero__title-pen {
  @include media-query($breakpoint--small) {
    transform: translateX(3%);
  }
}

.home-hero__image {
  margin-top: -#{spacing(4)};
  grid-row-start: 2;
  position: relative;

  @include media-query($breakpoint--small) {
    margin-top: -#{spacing(7)};
  }

  @include media-query($breakpoint--large) {
    margin-top: -#{spacing(10)};
  }
  
  @include media-query($breakpoint--x-large - 1, max-width) {
    grid-column: 2 / 8;
  }

  @include media-query($breakpoint--x-large) {
    margin-top: -#{spacing(14)};
  }
}

.home-hero__image-wrapper {
  max-width: 70rem;
}

.home-hero__image-thumb {
  padding-top: calc(759.57 / 725.25 * 100%);
  width: 100%;
}

.home-hero__cta {
  margin-top: -13%;
  width: 100%;
  grid-column: 1 / 7;
  text-align: center;

  @include media-query($breakpoint--x-large) {
		grid-column: 2 / 9;
    margin-top: -10%;
	}

	@include media-query($breakpoint--xx-large) {
		grid-column: 3 / 9;
	}
}

.home-hero__label {
  display: none;

  @include media-query($breakpoint--large) {
    display: block;
    grid-column: 1 / 2;
    grid-row: 2;
    z-index: 2;
  }

  @include media-query($breakpoint--xx-large) {
    grid-column: 1 / 3;    
  }
}

.home-hero__intro {
  display: none;

  @include media-query($breakpoint--large) {
    grid-column: 7 / 9;
    display: flex;
    grid-row: 2;
    z-index: 2;
    align-items: center;
    transform: translateY(-20%);
  }
  
  @include media-query($breakpoint--x-large) {
    max-width: 100rem;
    grid-column: 9 / 11;
  }

  .type--body {
    margin-left: auto;
    margin-right: auto;
    max-width: 30rem;
  }
}