.bg--white {
  background-color: $colour--white;
}

.bg--grit-50 {
  background-color: $colour--grit-50;
}

.bg--grit-svg-500 {
  fill: $colour--grit-500;
}