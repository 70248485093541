.relative {
  position: relative;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.sticky {
  position: sticky;
}