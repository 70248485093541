.no-overflow {
  overflow: hidden;
}

.no-overflow-x {
  overflow-x: hidden;
}

.no-overflow-y {
  overflow-y: hidden;
}

.no-scrolling {
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}