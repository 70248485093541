@mixin media-query($device, $width-type: null) {
	@if $width-type == null {
		@media screen and (min-width: $device) {
			@content;
		}
	} @else {
		@media screen and (#{$width-type}: $device) {
			@content;
		}
	}
}