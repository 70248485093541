.nav-bar {
  padding: spacing(2) 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;

  &.inverted {
    transition: color 300ms ease;
    color: $colour--white;

    .hamburger__line {
      background-color: $colour--white;
      transition: background-color 300ms ease;
    }
  }
}

.nav-bar__logo {
  grid-column: 1 / 2;
}

.nav-bar__meta-one,
.nav-bar__meta-two {
  @include media-query($breakpoint--medium) {
    display: flex;
    align-items: center;
  }
}

.nav-bar__meta-one {
  grid-column: 3 / 4;

  @include media-query($breakpoint--small) {
    grid-column: 3 / 5;
  }

  @include media-query($breakpoint--medium) {
    grid-column: 3 / 5;
  }

  @include media-query($breakpoint--x-large) {
    grid-column: 4 / 6;
  }
}

.nav-bar__meta-two {
  @include media-query($breakpoint--small - 1, max-width) {
    display: none;
  }

  @include media-query($breakpoint--small) {
    grid-column: 5 / 7;
  }

  @include media-query($breakpoint--x-large) {
    grid-column: 6 / 8;
  }
}

.nav-bar__trigger {
  display: flex;
  align-items: center;
  justify-content: right;
  grid-column: 6 / 7;

  @include media-query($breakpoint--small) {
    grid-column: 8 / 9;
	}

  @include media-query($breakpoint--x-large) {
    grid-column: 10 / 11;
  }
}