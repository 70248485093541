.error-grid {
  min-height: 100%;
  padding: spacing(2) 0;

  @include media-query($breakpoint--large) {
    padding: spacing(6) 0;
  }
}

.error-grid__column {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  grid-column: 1 / 7;
  grid-row-start: 2;
  grid-row-end: 4;

  @include media-query($breakpoint--small) {
    grid-column: 1 / 6;
  }

  @include media-query($breakpoint--large) {
    grid-column: 4 / 8;
  }

  @include media-query($breakpoint--x-large) {
    grid-column: 7 / 11;
    grid-row-start: 1;
    grid-row-end: 5;
  }

  .meta {
    max-width: 20rem;
  }

  .mask-title__l-word {
    &:first-child {
      @media screen and (min-width: $breakpoint--x-large) and (max-width: $breakpoint--xx-large - 1) {
        padding-bottom: 10%;
      }
    }
  }
}

.error-grid__image {
  @include media-query($breakpoint--large - 1, max-width) {
    display: none;
  }

  @include media-query($breakpoint--large) {
    grid-column: 1 / 3;
    grid-row-start: 3;
    grid-row-end: 4;
    padding-top: calc(450 / 400 * 100%);
    background-color: grey;
  }

  @include media-query($breakpoint--x-large) {
    grid-column: 3 / 6;
    grid-row-start: 4;
    grid-row-end: 5;
    align-self: flex-end;
  }
}

.error-grid__socials {
  grid-column: 1 / 7;
  grid-row-start: 5;

  @include media-query($breakpoint--small - 1, max-width) {
    display: flex;
    align-items: flex-end;
  }

  @include media-query($breakpoint--small) {
    grid-column: 4 / 5;
    grid-row-start: 1;
  }

  @include media-query($breakpoint--medium) {
    margin-bottom: spacing(14);
  }

  @include media-query($breakpoint--large) {
    grid-column: 4 / 6;
  }

  @include media-query($breakpoint--x-large) {
    grid-column: 3 / 5;
    margin-bottom: 0;
  }

  .link {
    margin-right: 2.4rem;

    @include media-query($breakpoint--small) {
      display: table;
      margin-top: spacing(1);
      margin-right: 0;
    }

    &:first-child {
      @include media-query($breakpoint--small) {
        margin-top: 0;
      }
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.error-grid__message {
  max-width: 42rem;

  @include media-query($breakpoint--medium) {
    max-width: 47rem;
  }

  @include media-query($breakpoint--xx-large) {
    max-width: 52rem;
  }
}

.error-grid__logo {
  grid-column: 1 / 3;

  @include media-query($breakpoint--small) {
    grid-row-start: 1;
  }

  @include media-query($breakpoint--medium - 1, max-width) {
    margin-bottom: spacing(8);
  }

  a {
    display: table;
  }
}

.error-grid__code {
  grid-column: 6 / 7;
  grid-row-start: 1;
  margin-top: -25%;
  display: flex;
  flex-direction: column;

  @include media-query($breakpoint--small) {
    grid-column: 8 / 9;
  }

  @include media-query($breakpoint--medium) {
    margin-top: -20%;
  }

  @include media-query($breakpoint--large) {
    margin-top: -15%;
  }

  @include media-query($breakpoint--x-large - 1, max-width) {
    align-items: flex-end;
  }
  
  @include media-query($breakpoint--x-large) {
    grid-row-end: 5;
    grid-row-start: 4;
    grid-column: 1 / 2;
    justify-content: flex-end;
    margin-top: 0;
  }
}

.error-grid__char {
  max-height: 5rem;
}

.error--grid__c-pad {
  padding-top: 0.4rem;

  @include media-query($breakpoint--medium) {
		padding-top: 6rem;
	}
}