.mask-title--l {
  line-height: 0.7;
  letter-spacing: -1;
}

.mask-title__word {
  padding-bottom: 5%;
}

.mask-title__l-word {
  padding-bottom: 7%;
}
