/* Colours */
$colour--passion: #f56308;
$colour--grit-500: #202020;
$colour--grit-400: #3C3C3C;
$colour--grit-300: #535353;
$colour--grit-200: #949494;
$colour--grit-100: #D4D4D4;
$colour--grit-50: #F2F2F2;

$colour--white: white;
$colour--black: black;

/* Type */
$type--line-height: 1.5;
$type--family-display: "freight-display-pro", serif;
$type--family-body: "freight-text-pro", serif;
$type--family-alt: "freight-sans-pro", sans-serif;

$type--font-weight-reg: 400;
$type--font-weight-semi: 600;
$type--font-weight-bold: 700;

/* Spacing */
$spacing-rule: 0.8rem;
$spacing-rule-half: calc($spacing-rule / 2);
$spacing-rule-quarter: calc($spacing-rule-half / 2);

/* Breakpoints */
$breakpoint--small: 550px;
$breakpoint--medium: 768px;
$breakpoint--large: 960px;
$breakpoint--x-large: 1200px;
$breakpoint--tweakpoint: 1470px;
$breakpoint--xx-large: 1600px;
$breakpoint--xxx-large: 2000px;

/* Animation Easing */
$cubic-bezier--zoom: cubic-bezier(0.420,0.000,0.002,1.000);

/* Border */
$border-top: 2px solid $colour--grit-500;
$rotation-angle-minus--l: rotate(-4deg);
$rotation-angle--l: rotate(4deg);
$rotation-angle: rotate(2deg);
$rotation-angle-minus: rotate(-2deg);
