@mixin section--s($margin-pos: bottom, $property: margin) {
  #{$property}-#{$margin-pos}: spacing(6);

  @include media-query($breakpoint--small) {
    #{$property}-#{$margin-pos}: spacing(8);
  }
  
  @include media-query($breakpoint--medium) {
    #{$property}-#{$margin-pos}: spacing(10);
  }

  @include media-query($breakpoint--x-large) {
    #{$property}-#{$margin-pos}: spacing(13);
  }
}