.nav-menu {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  overflow-y: scroll;
  visibility: hidden;
}

.nav-menu__bg {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  svg {
    fill: $colour--grit-500;
  }
}

.nav-menu__list {
  margin: spacing(15) auto 0;
  display: table;
  z-index: 6;
  position: relative;
}

.nav-menu__list-item {
  position: relative;

  .mask-title__word {
    transition: color 300ms ease;
  }
}

.no-touch {
  .nav-menu__preview {
    width: 17rem;
    height: 17rem;
    top: 0;
    left: 0;
    pointer-events: none;
    position: absolute;
    transform: scale(0);
  
    @include media-query($breakpoint--medium) {
      width: 25rem;
      height: 25rem;
    }
  }
}


.nav-menu-preview__img {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding-top: 100%;
}

.nav-menu__link {
  @extend .type--l;
  
  display: inline-block;
  z-index: 2;
  position: relative;
  pointer-events: none;
  padding: spacing(3) 0;

  @include media-query($breakpoint--large) {
		font-size: 12.8rem;
  }
}

.menu-is-open .nav-menu__link {
  pointer-events: auto;
}

.nav-menu__list-item:hover ~ .nav-menu__list-item .mask-title__word,
.nav-menu__list-item:has(~ .nav-menu__list-item:hover .mask-title__word) {
  color: $colour--grit-300 !important;
}

.menu-is-open .nav-menu__list-item:hover .nav-link__line {
  transform: translateX(0);
  transition: transform 300ms ease;
}

.nav-link__line {
  @include underline(0.3rem, false);
  position: absolute;
  left: 0;
  bottom: 20%;
  background-color: $colour--white;
  transform: translateX(-101%);
  transition: transform 300ms ease;
}