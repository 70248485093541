.services {
  @include media-query($breakpoint--small) {
    grid-column: 1 / 8;
  }

  @include media-query($breakpoint--large) {
    grid-column: 3 / 8;
  }

  @include media-query($breakpoint--x-large) {
    grid-column: 3 / 9;
  }

  @include media-query($breakpoint--xx-large) {
    grid-column: 4 / 9;
  }
}

.services__call-out {
  grid-column: 1 / 9;

  @include media-query($breakpoint--large) {
    text-align: center;
  }

  @include media-query($breakpoint--x-large) {
    grid-column: 1 / 11;
  }

  .type--call-out {
    @include media-query($breakpoint--x-large) {
      max-width: none;
    }

    @include media-query($breakpoint--x-large - 1, max-width) {
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.services__list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: spacing(2);
  grid-row-gap: spacing(2);

  @include media-query($breakpoint--small) {
    grid-column-gap: spacing(3);
    grid-row-gap: spacing(3);
  }
}

.services__list-item {
  font-family: $type--family-display;
  font-weight: $type--font-weight-reg;
  font-size: 1.6rem;

  @include media-query($breakpoint--small) {
    font-size: 1.8rem;
  }

  @include media-query($breakpoint--medium) {
    font-size: 2.4rem;
  }

  @include media-query($breakpoint--x-large) {
    font-size: 3.2rem;
  }
}