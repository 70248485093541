/* Typography */
$type--tweakpoint: 450px;

html {
	font-size: 62.5%;

	@include media-query($breakpoint--xxx-large) {
		font-size: 0.50vw;
	}
}

body {
	font-family: $type--family-body;
	font-size: 100%;
}

.type--xl,
.type--l,
.type--m,
.type--s {
	font-family: $type--family-display;
	font-weight: 700;
}

.type--xl {
	font-size: 6.4rem;
	line-height: 1.1;

	@include media-query($breakpoint--small) {
		font-size: 9.6rem;
		line-height: 1;
	}

	@include media-query($breakpoint--medium) {
		font-size: 12.8rem;
	}

	@include media-query($breakpoint--x-large) {
		font-size: 18rem;
	}

	@include media-query($breakpoint--xxx-large) {
		font-size: 22rem;
	}

}

.type--xxl {
	@extend .type--xl;

	@include media-query($breakpoint--tweakpoint) {
		font-size: 22rem;
	}

	@include media-query($breakpoint--xxx-large) {
		font-size: 26rem;
	}
}

.type--l {
	font-size: 4.8rem;
	line-height: 1.1;

	@include media-query($breakpoint--small) {
		font-size: 6rem;
	}

	@include media-query($breakpoint--medium) {
		font-size: 7.2rem;
	}

	@include media-query($breakpoint--x-large) {
		font-size: 8.4rem;
	}
}
  
.type--m {
	font-size: 3.2rem;
	line-height: 1.3;

	@include media-query($breakpoint--medium) {
		font-size: 4.8rem;
		line-height: 1.1;
	}

	@include media-query($breakpoint--x-large) {
		font-size: 6rem;
	}

}
 
.type--s {
	font-size: 2.4rem;
	line-height: 1.3;

	@include media-query($breakpoint--medium) {
		font-size: 3.2rem;
		line-height: 1.2;
	}

	@include media-query($breakpoint--x-large) {
		font-size: 4.8rem;
	}
}

.type--call-out {
	font-family: $type--family-display;
	font-weight: $type--font-weight-reg;
	line-height: 1.3;
	font-size: 4rem;
	
	@include media-query($breakpoint--small) {
		font-size: 7vw;
	}

	@include media-query($breakpoint--large) {
		font-size: 5.5vw;
	}
}

.type--body {
	@include text-size;

	& {
		line-height: $type--line-height;
		margin: 0;
	}
	
	& + & {
		margin-top: spacing(3);
	}
}

.type--alt-xl {
	@include capitalised-text(true);
	@include text-size(1.6, 2.4, 3.2);
}

.type--alt-l {
	@include capitalised-text(true);
	@include text-size;
}

.type--alt-s {
	@include capitalised-text(true);
	@include text-size(1.2, null, 1.4);
}

.type--alt-xs {
	@include capitalised-text(true);
	@include text-size(1.2, null, 1.2);
}

.type--letter-sp {
	letter-spacing: 1px;
}

.type--l-margin {
	margin: 0 0 spacing(5);

	@include media-query($breakpoint--small) {
		margin: 0 0 spacing(7);
	}

	@include media-query($breakpoint--medium) {
		margin: 0 0 spacing(9);
	}
}

.type--m-margin {
	margin: 0 0 spacing(4);

	@include media-query($breakpoint--medium) {
		margin: 0 0 spacing(5);
	}
}

.type--s-margin {
	margin: 0 0 spacing(2);

	@include media-query($breakpoint--medium) {
		margin: 0 0 spacing(3);
	}
}

.type--italic {
	font-style: italic;
}

.type--uppercase {
	@include capitalised-text;
}

.type--semi-bold {
	font-weight: $type--font-weight-semi;
}

.type--grit-500 {
	color: $colour--grit-500;
}

.type--grit-400 {
	color: $colour--grit-400;
}

.type--grit-300 {
	color: $colour--grit-300;
}

.type--grit-200 {
	color: $colour--grit-200;
}

.type--grit-100 {
	color: $colour--grit-100;
}

.type--grit-50 {
	color: $colour--grit-50;
}

.type--white {
	color: $colour--white;
}

.type--family-alt {
	font-family: $type--family-alt;
}

.white-space {
	white-space: pre;
}
