.project {
  @extend .bg--white;
}

.projects {
  grid-row-gap: spacing(10);

	@include media-query($breakpoint--medium) {
		grid-row-gap: spacing(13);
	}

	@include media-query($breakpoint--x-large) {
		grid-row-gap: spacing(20);
	}

	@include media-query($breakpoint--xx-large) {
		grid-row-gap: spacing(28);
	}
}

.project__card {
  display: block;

  & + & {
    @include media-query($breakpoint--small - 1, max-width) {
      margin-top: spacing(6);
    }
  }

  &:first-child,
  &:nth-child(7n) {
    @include media-query($breakpoint--small) {
      grid-column: 1 / 8;
    }

    @include media-query($breakpoint--medium) {
      grid-column: 1 / 5;
    }

    @include media-query($breakpoint--x-large) {
      grid-column: 1 / 6;
    }
  }

  &:nth-child(2),
  &:nth-child(8n) {
    @include media-query($breakpoint--small) {
      grid-column: 3 / 7;
    }

    @include media-query($breakpoint--medium) {
      margin-top: spacing(45);
      grid-column: 6 / 9;
    }

    @include media-query($breakpoint--x-large) {
      grid-column: 8 / 11;
    }
  }

  &:nth-child(3),
  &:nth-child(9n) {

    @include media-query($breakpoint--small) {
      grid-column: 1 / 6;
    }

    @include media-query($breakpoint--medium) {
      grid-column: 3 / 7;
    }
  }

  &:nth-child(4),
  &:nth-child(10n) {

    @include media-query($breakpoint--small) {
      grid-column: 4 / 9;
    }

    @include media-query($breakpoint--medium) {
      grid-column: 1 / 4;
    }

    @include media-query($breakpoint--x-large) {
      grid-column: 2 / 5;
    }
  }

  &:nth-child(5),
  &:nth-child(11n) {
    @include media-query($breakpoint--small) {
      grid-column: 4 / 7;
      margin-top: spacing(13);
    }

    @include media-query($breakpoint--medium) {
      grid-column: 5 / 7;
      margin-top: spacing(28);
    }

    @include media-query($breakpoint--x-large) {
      grid-column: 7 / 11;
    }
  }

  &:nth-child(6),
  &:nth-child(12n) {
    @include media-query($breakpoint--small) {
      grid-column: 3 / 6;
    }

    @include media-query($breakpoint--x-large) {
      grid-column: 4 / 7;
    }
  }

  &:hover .project__img {
    transform: scale(1.5) !important;
    transition: 300ms ease;
  }
}

.project__meta {
  display: flex;
  justify-content: space-between;
  padding-top: spacing(3);

  @include media-query($breakpoint--medium) {
    padding-top: spacing(5);
  }
}

.project__next {
  height: 30rem;
  overflow: hidden;

  @include media-query($breakpoint--small) {
    height: 40rem;
  }

  @include media-query($breakpoint--x-large) {
    height: 50rem;
  }
}

.project__next-wrapper {
  @include media-query($breakpoint--small - 1, max-width) {
    margin-left: auto;
    margin-right: auto;
    max-width: 50rem;
  }

  @include media-query($breakpoint--small) {
    grid-column: 1 / 9;
  }

  @include media-query($breakpoint--x-large) {
    grid-column: 2 / 10;
  }
}

.project__next-thumb {
  display: block;
  margin-top: -#{spacing(4)};
  transform: $rotation-angle;

  @include media-query($breakpoint--small) {
    margin-top: -#{spacing(6)};
  }

  @include media-query($breakpoint--tweakpoint) {
    margin-top: -#{spacing(8)};
  }
}

.project__next-img {
  padding-top: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.project__next-title {
  position: relative;
  z-index: 2;
}