@keyframes scroll-btn-hover {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(100%);
  }

  50.01% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0);
  }
}